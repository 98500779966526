/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.app-body[dir="rtl"] .bucketItem .bucket-container .swiper-container .swiper-button-next,
.app-body[dir="rtl"] .bucketItem .bucket-container .swiper-container .swiper-button-prev {
  transform: scale(-1);
  left: -8.2vw; }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .app-body[dir="rtl"] .bucketItem .bucket-container .swiper-container .swiper-button-next,
    .app-body[dir="rtl"] .bucketItem .bucket-container .swiper-container .swiper-button-prev {
      left: -18.2vw; } }

.app-body[dir="rtl"] .image-thumbnail.thumnail-hover:hover .carousel-bottom-overlay {
  flex-direction: row-reverse; }

.app-body[dir="rtl"] .image-thumbnail.selected .carousel-bottom-overlay {
  flex-direction: row-reverse; }

.disable-click {
  pointer-events: none; }

.bucketItem .bucketItem--header {
  margin: 45px;
  font-size: 1.0625em;
  margin-left: 63px;
  margin-right: 64px;
  margin-bottom: 15px;
  color: white;
  font-family: "changa"; }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .bucketItem .bucketItem--header {
      margin: 20px;
      font-size: 18px; } }
  @media screen and (min-width: 1368px) and (max-width: 1600px) {
    .bucketItem .bucketItem--header {
      margin-left: 52px;
      margin-right: 55px; } }

.bucketItem .bucket-container .image-thumbnail .overlay-color {
  bottom: 0; }

.bucketItem .bucket-container .swiper-container {
  width: calc(100% - (120px));
  font-size: 1.0625em;
  overflow: visible;
  margin: 0 63px;
  z-index: 1; }
  @media screen and (min-width: 768px) and (max-width: 991px) and (orientation: portrait) {
    .bucketItem .bucket-container .swiper-container {
      margin: 0 16px; } }
  @media screen and (max-width: 768px) {
    .bucketItem .bucket-container .swiper-container {
      margin: 0 0; } }
  @media screen and (min-width: 1368px) and (max-width: 1600px) and (orientation: landscape) {
    .bucketItem .bucket-container .swiper-container {
      margin: 0 51px; } }
  .bucketItem .bucket-container .swiper-container .swiper-button-next {
    background-image: url("~app/resources/assets/thumbnail/ic-right-arrow.png");
    background-position: left 10% center; }
  .bucketItem .bucket-container .swiper-container .swiper-button-prev {
    background-image: url("~app/resources/assets/thumbnail/ic-left-arrow.png");
    background-position: right 10% center; }
  .bucketItem .bucket-container .swiper-container .swiper-button-next,
  .bucketItem .bucket-container .swiper-container .swiper-button-prev {
    background-size: 20%;
    top: 22px;
    width: 10vw;
    height: 100%; }

.bucketItem .bucket-container .image-thumbnail .carousel-image img {
  display: block;
  width: 100%;
  opacity: 1;
  border-radius: 5px;
  transition: opacity 0.3s ease;
  height: 100%;
  object-fit: cover; }
  .bucketItem .bucket-container .image-thumbnail .carousel-image img:first-child {
    position: absolute; }

.bucketItem .bucket-container .image-thumbnail .carousel-default-image img {
  width: 100%;
  opacity: 1;
  border-radius: 5px;
  transition: opacity 0.3s ease;
  display: block; }

.bucketItem .bucket-container .image-thumbnail .play-button {
  top: 50%; }

.bucketItem .bucket-container.sliding .swiper-button-next,
.bucketItem .bucket-container.sliding .swiper-button-prev {
  display: none; }

.ltr .bucketItem .bucket-container .swiper-container .swiper-button-next {
  right: -18.3vw; }
  @media (min-width: 991px) {
    .ltr .bucketItem .bucket-container .swiper-container .swiper-button-next {
      right: -9.3vw; } }

.ltr .bucketItem .bucket-container .swiper-container .swiper-button-prev {
  left: -9.3vw; }

.rtl .bucket-container .swiper-container .swiper-button-next {
  left: 0; }

.rtl .bucket-container .swiper-container .swiper-button-prev {
  right: -8.3vw; }

@media only screen and (max-width: 1280px) {
  .bucketItem .bucket-container .swiper-container {
    width: calc(100% - (60px) * 2); }
    .bucketItem .bucket-container .swiper-container .swiper-button-prev {
      background-position: right 15% center; }
    .bucketItem .bucket-container .swiper-container .swiper-button-next {
      background-position: left 15% center; } }

@media only screen and (max-width: 745px) {
  .bucketItem .bucketItem--header {
    margin: 20px;
    margin-bottom: 8px;
    margin-left: 10px; }
  .bucketItem .bucket-container .swiper-container {
    width: calc(90%);
    margin: 0 4px; }
    .bucketItem .bucket-container .swiper-container .swiper-button-prev {
      display: block;
      background-position: right 20% center;
      display: none; }
    .bucketItem .bucket-container .swiper-container .swiper-button-next {
      display: block;
      background-position: left 20% center;
      display: none; } }

@media only screen and (max-width: 320px) {
  .bucketItem .bucket-container .swiper-container {
    margin: 0; }
    .bucketItem .bucket-container .swiper-container .swiper-button-next,
    .bucketItem .bucket-container .swiper-container .swiper-button-prev {
      display: none; } }
