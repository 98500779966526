/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
 @import "app/resources/styles/variables.scss"; 

 .app-body[dir="rtl"] {
   .app-footer {
     .z5-text {
       margin-right: 10px;
       font-weight: 400;
       font-family: "changa";
     }
 
     .router-link {
       border-left: 1px solid;
       border-right: none;
       padding-left: 8px;
       margin-right: 0;
       padding-right: 0;
       @media(max-width:745px){
        padding-left: 0px;
       }
       .router-link:hover {
        color: #EE7623; /* Change text color on hover */
      }
       &:first-child {
         border-right: none;
       }
 
       &:last-child {
         border-left: none;
        //  margin-right: 8px;
       }
     }
 
     .footer-bottom {
       padding-right: 15px;
       font-family: "changa";
     }
 
     .footer-top {
       color: #ffffff;
       font-family: "changa";
     
       .footer-top-left {
        margin-bottom: 20px; 
        .links {
          display: flex;
        flex-wrap: nowrap;
        letter-spacing: 0px;
        font-family: 'changa-Regular';
        font-size: 15px;
        }
      
        .links > div {
          padding: 0px 38px;
          border-right: 1px solid #ffff;
        }
        // @media(max-width:767px){
        //   padding: 0px 30px;
        // }
        .links > div:first-child {
          border-right: none; 
        }
        .links {
          border-right: none; 
        }
      
        .links a {
          color: #ffffff; 
          text-decoration: none; 
        }
      
        .links a:hover {
          color: #EE7623;
        }
            
       
      
   
       }
       .footer-top-right {
         .follow-us > span {
           margin-right: 24px;
           position: relative;
           width:73px;
           height:23px;
         }
         .download-app{

           @media (max-width: 490px){
            width: 100%;
            margin-left: 0 ;
            align-items: center;
            padding: 10px 6px;
           }
         }
         .download-app > span {
           margin-left: 15px;
           color: #ffffff;
           @media (max-width: 390px){
            margin-right: 0;
            
           }
         }
       }
     }
   }
 }
 
 .app-footer {
  
   margin-top: 0px;
   align-self: flex-end;
   width: 100%;
   z-index: 99;
  // z-index: 9;
   display: flex;
   font-family: "changa";
   flex-direction: column;
   -webkit-box-pack: end;
   -ms-flex-pack: end;
   justify-content: flex-end;
   color: $app-footer-color;
  //  background-color: black;
  background-color: #0F1822;;
   font-size: 0.75em;
   font-weight: 400;
   padding-top: 20px;
  //  -webkit-box-shadow: black 0 1px 0;
   box-shadow: black 0 1px 0;
   justify-self: flex-end;
  //  @media (min-width: 745px) {
  //    font-size: 1em;
  //    margin-bottom: 3.5rem !important;
     
  //  }
 
   .z5-text {
     margin-left: 7px;
     font-weight: 400;
   }
   @media (max-width: 745px) {
     .z5-text {
       margin-left: 7px;
       font-weight: 400;
     }
   }
 
   .router-link {
     text-decoration: none;
   }
 
   .footer-top {
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
     -webkit-box-orient: vertical;
     -webkit-box-direction: reverse;
     flex-direction: column;   
     align-items:center;
     -webkit-box-pack: justify;
     -ms-flex-pack: justify;
     justify-content: space-between;
     padding: 20px 20px;
 
     @media (min-width: 540px) {
   
     }
     @media (min-width: 840px) {
       -webkit-box-orient: horizontal;
       -webkit-box-direction: normal;
       flex-direction: row;
       -webkit-box-align: end;
       -ms-flex-align: end;
       align-items: flex-end;
     }
     @media (max-width: 1023px) {
       padding: 20px;
     }
 
     .footer-top-left {
      margin-bottom: 20px; 
      .links {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        letter-spacing: 0px;
        font-family: "changa-Regular"; 
        font-size: 15px;
      }
    
      
      .links > div {
        display: flex; 
        align-items: center; 
        padding: 0px 38px;
        border-right: 1px solid #ffffff; 
        height: 100%; 
      }
    
      
      .links > div:last-child {
        border-right: none; 
      }
    
      
      .links a {
        color: #ffffff;
        text-decoration: none; 
        height: 100%; 
        display: flex;
        align-items: center; 
      }
    
      
      .links a:hover {
        color: #EE7623;
      }
    }
    
 
     .footer-top-right {
       position: relative;
       top: 10px;
       display: -webkit-box;
       display: -ms-flexbox;
       display: flex;
       -webkit-box-orient: vertical;
       -webkit-box-direction: reverse;
       flex-direction: column;
       -webkit-box-align: start;
       -ms-flex-align: start;
       align-items: flex-start;
       @media (min-width: 540px) {
         -webkit-box-align: end;
         -ms-flex-align: end;
         align-items: flex-end;
         top: 0;
       }
       @media (min-width: 1350px) {
         -webkit-box-orient: horizontal;
         -webkit-box-direction: normal;
         flex-direction: row;
       }
 
       .download-app {
         display: -webkit-box;
         display: -ms-flexbox;
         display: flex;
         -webkit-box-align: end;
         -ms-flex-align: end;
         align-items: flex-end;
         margin-bottom: 5px;
         border: 1px solid #ffffff;
         border-radius: 16px;
         padding: 10px 35px;
         margin-left: 10px;
 
         @media (min-width: 1350px) {
           // margin-right: 40px;
         }
 
         > span {
           margin-right: 20px;
           // display: none;          
           color: #ffffff;
           position: relative;
           top: -7px;
 
           @media (min-width: 1024px) {
             display: inline;
           }
         }
         a {
           margin-left: 10px;
           margin-right: 10px;
         }
 
         > img {
           position: relative;
           top: 15px;
           margin-right: 2px;
           width: 100px;
           cursor: pointer;
         }
 
         > img:first-of-type {
           margin-left: -2px;
         }
 
         .ic-googleplay {
           display: inline-block;
           width: 138px;
           height: 50px;
         }
 
         .ic-appstore {
           display: inline-block;
           width: 131px;
           height: 49px;
         }
       }
 
       .follow-us {
         display: -webkit-box;
         display: -ms-flexbox;
         display: flex;
         -webkit-box-pack: start;
         -ms-flex-pack: start;
         justify-content: flex-start;
         -webkit-box-align: end;
         -ms-flex-align: end;
         align-items: flex-end;
 
         position: relative;
        //  top: -20px;
 
         @media (max-width: 745px) {
           top: 0;
         }
 
         > span {
           // display: none;
           margin-right: -7px;          
           color: #ffffff;
           position: relative;
           top: -7px;
           width: 104px;
           height: 24px;

 
           @media (min-width: 745px) {
             display: inline;
           }
         }
 
       
       }
     }
   }
 
   .footer-bottom { 
 
     text-align: center;
     padding: 13px 20px;
     font-size: 18px;
     color: #ffffff;
 
     @media (max-width: 1023px) {
       padding: 2px 0 2px 15px;
     }
   }
 
   &.arabic {
     .footer-top {
       -webkit-box-orient: vertical;
       -webkit-box-direction: reverse;
       flex-direction: column;
       -webkit-box-align: end;
       -ms-flex-align: end;
       align-items: flex-end;
       @media (min-width: 540px) {
         -webkit-box-orient: horizontal;
         -webkit-box-direction: reverse;
         flex-direction: row;
       }
 
       .footer-top-left {
         .links {
           > a {
             color: #ffffff;
             -webkit-box-orient: horizontal;
             -webkit-box-direction: reverse;
             flex-direction: row;
             margin-right: 0;
             margin-left: 8px;
             padding-right: 0;
             padding-left: 8px;
             border-right: none;
             border-left: 1px solid;
             font-family: "changa";
             font-size: 18px;

 
             &:last-child {
               padding-left: 0;
               border-left: none;
             }
           }
         }
       }
 
       .footer-top-right {
         -webkit-box-align: end;
         -ms-flex-align: end;
         align-items: flex-end;
         @media (min-width: 540px) {
           -webkit-box-align: start;
           -ms-flex-align: start;
           align-items: flex-start;
         }
         @media (min-width: 1350px) {
           -webkit-box-orient: horizontal;
           -webkit-box-direction: reverse;
           flex-direction: row;
           -webkit-box-align: end;
           -ms-flex-align: end;
           align-items: flex-end;
         }
 
         .download-app {
           -webkit-box-orient: horizontal;
           -webkit-box-direction: reverse;
           flex-direction: row;
           @media (min-width: 1350px) {
             margin-right: 0;
             margin-left: 40px;
           }
 
           > span {
             margin-right: 0;
             margin-left: 20px;
           }
 
           > img:last-child {
             margin-left: -2px;
           }
         }
 
         .follow-us {
           -webkit-box-orient: horizontal;
           -webkit-box-direction: reverse;
           flex-direction: row;
 
           > span {
             margin-right: 0;
             margin-left: 20px;
           }
         }
       }
     }
 
     .footer-bottom {
       -webkit-box-pack: end;
       -ms-flex-pack: end;
       justify-content: flex-end;
       padding-left: 0;
       padding-right: 15px;
     }
   }
 }

 .ltr .app-footer .footer-top .footer-top-right .download-app {
   margin-left: 0;
   margin-right: 50px;
   @media (max-width: 390px){
    width: 100%;
    padding: 5px 0px;
    align-items: center;
   }
   @media (max-width: 745px) {
     top: 0;
   }
 }
 @media (max-width: 390px){
  .footer-top{
    padding: 10px 5px !important;
  }
  .app-body.ltr .app-footer .footer-top .footer-top-right .download-app > span {
    left: 10px !important;
    
  }
 }
 
 @media (max-width: 1023px) {
   .footer-bottom {
     display: none;
   }
   .app-footer .footer-top .footer-top-left .links {
     top: 0 !important;
   }
   .footer-top-right {
     -webkit-box-align: center !important;
         -ms-flex-align: center !important;
         align-items: center !important;
   }
   .app-footer .footer-top .footer-top-right .download-app {
    //  margin-top: 20px;
     margin-bottom: 20px;    
     padding: 10px 5px;
   }
   .app-footer .footer-top .footer-top-left .links > a {
     font-size: 14px !important;
   }
   .app-footer .footer-top .footer-top-right .follow-us {
     top: 0 !important;
   }
   .app-footer .footer-top .footer-top-right {
     width: 100%;
   }
   .footer-top-left {
     width: 100%;
     text-align: center;
   }
   .app-footer .footer-top .footer-top-right .download-app > span {
     top: 0;
   }
   .app-body.ltr .app-footer .footer-top .footer-top-right .download-app > span {
     left: 15px;
     margin-right: 10px;
   }
   .ltr .app-footer .footer-top .footer-top-right .download-app {
     margin-right: 0;
     align-items: center;
   }
 }
 
 @media (max-width: 400px) {
  // .app-footer{
  //   padding-bottom: 2rem;
  // }
   .app-footer .footer-top .footer-top-right .download-app > span {
     margin-left: 0 !important;
     font-size: 10px;
     top: 0 !important;
   }
   .app-footer .footer-top .footer-top-left .links > a {
     font-size: 12px !important;
   }
   .app-footer .footer-top {
     padding: 10px 20px;
    //  margin-bottom: 3.5rem;
   }
 }
 .footer-bottom span{
    font-size: 30px;
    position: relative;
    top: 8px;
 }






 // media query added

 @media (min-width: 840px) {
  .app-footer .footer-top {
      flex-direction: column;
      align-items: center;
      margin-top: 50px;
  }
}

@media (min-width: 1350px) {
  .app-footer .footer-top .footer-top-right {
      flex-direction: column;
  }
}

.app-footer .footer-top .footer-top-right .follow-us {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    position: relative;
    gap: 10px;
    top: 5.5px;
}

@media (min-width: 540px) {
  .app-footer .footer-top .footer-top-right {
      align-items: center;
  }
}


// hovering CSS for  social media links



.follow-us a {
  display: inline-block;
  width: 45px;
  height: 45px; 
  background-size: cover;
}

.follow-us a.facebook {
  background-image: url(../../../resources/assets/footer/facebook1.svg);
}

.follow-us a.facebook:hover {
  background-image: url(../../../resources/assets/footer/facebookhover.svg);
}

.follow-us a.instagram {
  background-image: url(../../../resources/assets/footer/instagram1.svg);
}

.follow-us a.instagram:hover {
  background-image: url(../../../resources/assets/footer/instagramhover.svg);
}

.follow-us a.threads {
  background-image: url(../../../resources/assets/footer/threads.svg);
}

.follow-us a.threads:hover {
  background-image: url(../../../resources/assets/footer/threadshover.svg);
}

.follow-us a.twitter {
  background-image: url(../../../resources/assets/footer/twitter1.svg);
}

.follow-us a.twitter:hover {
  background-image: url(../../../resources/assets/footer/twitterhover.svg);
}

.follow-us a.youtube {
  background-image: url(../../../resources/assets/footer/youtube1.svg);
}

.follow-us a.youtube:hover {
  background-image: url(../../../resources/assets/footer/youtubehover.svg);
}

.follow-us a.tiktok {
  background-image: url(../../../resources/assets/footer/tiktok.svg);
}

.follow-us a.tiktok:hover {
  background-image: url(../../../resources/assets/footer/tiktokhover.svg);
}


.app-footer .footer-bottom {
  text-align: center;
  padding: 13px 20px;
  font-size: 13px;
  color: #ffffff;
  margin-top: -25px;
}


// 745px

@media (max-width: 745px) {
  .app-body[dir="rtl"] .app-footer .footer-top .footer-top-left .links {
    display: flex;
    flex-wrap: nowrap;
    letter-spacing: 0px;
    font-family: 'changa-Regular';
    font-size: 8px;
    padding-left: 0px;
    padding-right: 15px;
}

  .app-footer .footer-top .footer-top-left .links {
    display: flex;
    justify-content: center;
        padding: 0;
        font-size: 8px;
        white-space:break-spaces;
  }
  .app-footer .footer-top .footer-top-right .download-app a {
    margin-left: 9px;
    margin-right: 17px;
}
  .links {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    letter-spacing: 0px;
    font-family: "changa-Regular"; 
    font-size: 16px;
  }

  
  .links > div {
    display: flex; 
    align-items: center; 
    padding: 9px 38px;
    border-right: 1px solid #ffffff; 
    height: 100%; 
  }

  
  .links > div:last-child {
    border-right: none; 
  }

  
  .links a {
    color: #ffffff;
    text-decoration: none; 
    height: 100%; 
    display: flex;
    align-items: center; 
  }

  
  .links a:hover {
    color: #EE7623;
  }
  .download-app{
    width: 388px;
  }
  .download-app .img {
    height: 10%;
    width: 10%;
  }
}
