/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
 $header-height: 70px;
 @import "app/resources/styles/variables.scss";
 @import "app/App.scss";
 
 
 .app-body[dir="rtl"] {
   .app-menu {
     .app-menu-item-container {
       margin: 0;
       // margin-right: 40px;
       margin-top: 15px;
       // height: calc(100% - 15px);
       padding-right: 30px;
       padding-left: 0;
 
       @media only screen and(max-width:745px) {
         margin-right: -10px !important;
       }
     }
 
     .menu-sign-in {
       .select-box-elements {
         left: auto;
         right: 30px;
       }
 
       .user-menu-container {
         .user-menu-tab {
           >span {
             padding: 4px 26px 4px 2px;
             margin-right: 0;
           }
 
           .listing-filters {
             .select-box-container {
               // margin: 10px 0 10px -7px;
             }
           }
         }
       }
     }
   }
 
   .sidemenu {
     left: -456px;
     right: inherit;
 
     &.show {
       left: 45px;
       // left: 0px;
       // width: 100vw;
       // @media(max-width:1023px) and (min-width:768px){
         @media only screen and(max-width:1366px) and (min-width: 768px) {
         left: 12px;
       }
     }
     @media(max-width : 768px){
       left: -456px;
       right: inherit;
   
       &.show {
         // left: 180px;
         left: 0px;
         width: 100vw;
         z-index: 999;
       }
 
     }
   }
 
   // .marathon-5{
   //   width: 60px;
   //   height: 60px;
   //   border-radius: 40px;
   //   padding-left: 0px;
   //   color: #757575;
   //   font: normal normal 300 16px/30px Changa;
   // }
   .sidemenu span {
 
     &.closebtn {
       top: 0;
       left: 0;
       right: auto;
       margin-left: 25px;
     }
   }
 
 }
 
 .app-menu {
   @extend .scroll-bar;
   padding-top: 0;
   top: 70px;
   font-family: "changa";
 
   .app-menu-item-container {
     width: 200px;
     height: calc(100% - 25px);
     display: flex;
     flex-direction: column;
     padding: 0.6rem 0rem 0 1rem;
     z-index: 2;
 
     a {
       text-decoration: none;
     }
     .menu-items{
       display: flex;
       flex-direction: column;
       gap: 10px;
       height: 90px;
     }
   }
 
   .user-menu .user-menu-tab>span {
     padding: 0;
     color: #fff;
   }
 
   .user-icon1 {
     width: 13px;
     height: 13px;
     opacity: 1;
     cursor: pointer;
     @media(max-width:745px){
       margin-right: 14px;
       // margin-top:20px;
     }
   }
   @media (max-width: 745px) {
     .rtl .app-menu .user-icon1 {
         margin-right: 0;
         // margin-top:20px;
     }
 }
 
   .menu-sign-in { 
    cursor: pointer;
     display: flex;  
     align-items: center;
     padding: 0;  
     font: normal normal medium 15px/21px SF Pro Text;
     color: #5F5F5F;
     position: relative;
     .icon {
       width: 38px;
       margin-left: 0;
     }
 
     .btn {
       padding: 6px 3px;
       margin: 0;
 
       &.sign-in-btn {
         margin: -1px 5px;
        @media(max-width:745px){
         margin:0
        }
       }
 
       &:hover {
         background-color: transparent;
       }
 
       span {
         padding: 0;
         cursor: pointer;
         // font-weight: 400;
       }
     }
 
     .listing-filters {
       position: absolute;
       right: -15px;
       width: 10px;
       left: auto;
     }
   }
 
   .settings-family {
     // z-index: 1;
     // display: flex;
     // position: absolute;
     // top: 14em;
     // left: 33px;
     z-index: 1;
     display: flex;
     position: relative;
     // top: -1em;
   }
   
 
   .settings-familyAr {
     z-index: 1;
     display: flex;
     position: absolute;
     top: 15em;
     right: 27px;
   }
 
   .settings-textAr {
     color: #fff;
     cursor: pointer;
     position: relative;
     right: 20px;
   }
 
   .settings-text {
     color: #fff;
     cursor: pointer;
     margin: 0px;
     font-size: 15px;
     font-weight: 200;
     font-family: 'Changa';
     // position: relative;
     // right: 20px;
     // margin-left : -19px
 
 
   }
 
   .settings-btnAr {
     position: relative;
     right: 8px;
   }
 
   .settings-btn {
     // left: 10px;
     right: unset;
 
     @media screen and(max-width:768px) {
       left: 75px;
 
     }
   }
 
   .menuItem span {
     padding: 0px 0;
     height: 20px;
     display: block;
     font-size: 15px;  
     // font-weight: initial;
     // font-family: "changa";
 
     @media screen and(max-width:768px) {}
 
     &:hover {
       // background-color: $menu-menuitem-background-color;
       color: #fff;
     }
   }
 
   .static-menu {
     // margin-bottom: 48px;
 
     .menuItem span {
       // color: $static-menu-item-color;
       color: #5F5F5F;
 
       // &:hover {
       //   background-color: transparent;
       //   color: #fff;
       // }
     }
 
     .z5-menu-text {
       color: #757575;
       ;
     }
 
     .languagebtn-container {
       display: none;
     }
 
     @media screen and(max-width:767px) {
      
       .languagebtn-container {
         display: block;
       }
 
       .languagebtn-container .lanchange-btn {
         margin-right: 8px !important;
 
         span {
           padding: 6px;
         // margin-top: -2px;
         margin: -3px 5px -2px 0px;
         }
 
       }
     }
   }
 
   &.is-touch {
     .menuItem span {
       &:hover {
         background-color: none;
         color: #fff;
       }
     }
   }
 
   .settings-btn {
     position: relative;
     margin-right: 15px;
     cursor: pointer;
   }
 
 }
 
 .settings-text {
 
   // padding: 11px 11px 7px 39px;
   color: #929090
 }
 
 /*Device width pixel lesser than 860px */
 @media only screen and (max-width: 1023px) and (min-width:767px){
   .static-menu {
     margin-bottom: 10px;
     padding-bottom: 60px;
     // .languageButtonMobile{
     //   display: none;
     // }
   }
 
   .app-menu .menu-sign-in {
     padding-bottom: 0;
     margin-bottom: 20px;
   }
 
   .head-links-mobile {
     margin-bottom: 15px;
     padding: 3px;
     width: 350px;
     // overflow-x: auto;
     // overflow-y: hidden;
     white-space: nowrap;
   }
 
   .head-links-mobile1 {
     white-space: nowrap;
   }
 
   .app-body[dir="rtl"] .app-menu .app-menu-item-container {
     margin-top: 25px;
   }
 
   .app-menu {
     .menu-sign-in {     
       display: flex;
 
       .user-menu-container {
         .user-menu-tab {
           .listing-filters {
             .select-box-container {
               margin: 10px 0 10px -7px;
             }
           }
         }
       }
 
       .select-box-elements {
         left: -142px;
       }
     }
   }
 }
 
 /*Device width pixel lesser than 745*/
 @media only screen and (max-width: 768px) {
 
   .app-menu {
     bottom: 0;
     top: 160px;
     height: 100%;
 
     .menuItem span {
       &:hover {
         background-color: transparent;
         // color: $menu-item-color;
       }
     }
 
     .app-menu-item-container {
       height: 100%;
       padding-left: 11px;
     }
 
     &.is-touch {
       .menuItem span {
         &:hover {
           background-color: none;
           color: #fff;
         }
       }
     }
   }
   .app-menu-item-container{
     .static-menu{
       position: relative;
       top: 471px;
       left: 24px;
       @media(max-width:375px) and (max-height:800px){
         // top:335px;
         top:352px;
  
       }
       @media(max-width:375px) and (max-height:780px){
 
 
 }    }
   }
   .rtl {
     .app-menu-item-container{
     .static-menu{
       position: fixed;
       // bottom:30px;
       left:unset;
       @media(max-width:375px) and (max-height:800px){
         top:390px;
  
       }
     }
   }
   }
 }
 
 @media only screen and (max-width: 1023px)and (min-width:767px) {
   .app-menu {
     top: 160px;
   }
 }
 
 // .static-menu1 {
 //   a {
 //     display: flex;
 //     align-items: center;
 //     width: 390px !important;
 //     position: relative;
 //     top: 5em;
 
 //     span {
 //       padding-left: 8px;
 //       height: 40px;
 //       display: block;
 //       font-size: 16px;
 //       -webkit-margin-before: 0;
 //       -webkit-margin-after: 17px;
 //       -webkit-margin-start: 0;
 //       -webkit-margin-end: 0;
 //       font-weight: initial;
 //       color: #929090;
 //       font-family: "changa";
 //     }
 //   }
 // }
 //  @media(max-width:767px){
 .head-links-mobile1En{
   position: relative;
   top: 10em;
   left: -14px;
   z-index: 9999; 
   
 }
 .head-links-mobile1En li a {
   color: white;
      text-decoration: none;
      font: 300 15px / 18px 'SF Pro', sans-serif;
 }
 .head-links-mobile1En li {
   display: block;
   padding-bottom: 20px;
 }
 
 .head-links-mobile1 {
   position: relative;
   top: 8.5em;
   left: 42px;
   z-index: 100;
 }
 //  }
 .sidemenu-weyyak-logo{
   position: absolute;
   top:3em;
   left:2.7em;
 }
 .rtl .sidemenu-weyyak-logo{
   position: absolute;
   top:3em;
   right: 1em;
 }
 
 
 .head-links-mobile1 li a {
   color: white;
   text-decoration: none;
   pointer-events: auto;
 }
 .head-links-mobile1 li {
   display: block;
   padding-bottom: 20px;
   width: 100%;
   pointer-events: auto;
 }
 
 
 
 
 .menusignmobile {
   display: -webkit-flex;
   display: flex;
   width: calc(456px - 50px);
   padding: 0;
   padding-bottom: 13px;
   font: normal normal medium 15px/21px SF Pro Text;
   color: #5F5F5F;
   position: relative;
   margin-bottom: 10px;
 }
 
 
 @media(max-width:768px) {
   .app-menu .menu-sign-in {
     display: flex;
     width: calc(456px - 50px);
     font: normal normal medium 15px / 21px SF Pro Text;
     font-family: "changa-regular";
     color: #5F5F5F;
     position: relative;
     cursor: pointer;
   }
   .rtl .app-menu .menu-sign-in  {
     top: -9em;
     left:unset;
     .user-icon1{
       margin-right: 0;
       margin-left: 14px;
       cursor: pointer;
     }
   }
   .app-menu .menu-sign-in{
     top:-8em;
     left:1.7em;
 
   }
   .mobile-myplaylist {
     position: absolute;
     top: 26em;
     left:2.6em;
     font: normal normal 300 15px/21px SF Pro Text;
     font-family: "changa-regular";
     cursor: pointer;
     z-index: 9999;
   }
 
   .rtl .mobile-myplaylist {
     top: 25em;
     left:unset;
     cursor: pointer;
   }
 
   .settings-btn-mobile {
     margin: 0;
     display: flex;
     position: relative;    
     left: 192px !important;
     top:96px !important;
 
 //   @media(max-width:320px) and (max-height:658px){
 //     left: 175px !important;    
 //     top: 132px !important;
 // }
 
 // @media(min-width:321px) and (max-width:360px) and (max-height:640px){
 //     top: 125px !important;
 // }
 
 // @media(min-width:361px) and (max-width:375px) and (max-height:667px){
 //     left: 205px !important;
 //     top: 129px !important;
 // }
 // @media(min-width:361px) and (max-width:390px) and (max-height:844px){
 //   top: 132px !important;
 
 // }
 // @media(min-width:361px) and (max-width:430px) and (max-height:932px){
 //   top: 130px !important;
 
 // }
 
     .icon {
       width: 36px;
       height: 23px;
       margin-left: 0;
     }
     & :hover{
       background-color: none;
     }
   }
   .settings-btn-mobileAr {
     padding: 6px 3px;
     margin: 0;
     display: flex;
     position: relative;   
     top:137px;
     right: 192px;
     & :hover{
       background-color: none;
     }
   //   @media(max-width:320px) and (max-height:658px){
   //     right: 169px !important;
   //     top: 134px !important;
   // }
   
   // @media(min-width:321px) and (max-width:360px) and (max-height:640px){
   //   right: 205px !important;
   // }
   
   // @media(min-width:361px) and (max-width:375px) and (max-height:667px){
   //     right: 205px !important;
   //     bottom: 129px !important;
   // }
   // @media(min-width:361px) and (max-width:390px) and (max-height:844px){
   //   right: 132px !important;
   
   // }
   // @media(min-width:361px) and (max-width:430px) and (max-height:932px){
   //   right: 202px !important;
   //   top: 138px !important;
   
   // }
     .icon {
       width: 36px;
       height: 23px;
       margin-left: 0;
     }
   }
 
   .languageButtonMobile {
     position: absolute;
     bottom: 100px !important;
     left: 228px !important;
       // @media(max-width:1023px) and (min-width:768px){
         @media only screen and(max-width:1366px) and (min-width: 768px) {
       display: none !important;
     }
  
   }
   .languageButtonMobileAr{
     position: absolute;
     right: 210px!important;
     bottom: 59px !important;
     // @media(max-width:1023px) and (min-width:768px){   
       @media only screen and(max-width:1366px) and (min-width: 768px) {   
       display: none !important
     }
   }
   
 
 }
 
 .logoSubscribeAR{
   // display: flex;
 }
 .logoSubscribeEn{
   // display: flex;
 }
 .logoMobilesidemenuEN {
   display: none;
   height: 27px;
   position: absolute;
   top: 41px;
   right: 20px;
 }
 .logoMobilesidemenuAr{
   display: none;
   height: 27px;
   position: absolute;
   top: 43px;
   right: 20px;
 }
 .logoMobile {
 // display: none;
 }
 .mobile-subscribeAr{
   // height: 27px;
   position: absolute;
   top: 43px;
   left: 20px;
   z-index: 10;
 }
 .mobile-subscribeEN{
   // display: none;
   // height: 25px;
   position: absolute;
   top: 50px;
   right: 20px;
   white-space: nowrap;
   width: 121px;
 }
 .title-imagesMenuAr, .title-imagesMenuEn{
   filter: brightness(0) invert(1);
 }
 .title-imagesMenuEn{
   margin-right:14px;
 
 }
 .title-imagesMenuAr{
 margin-left:14px;
 }
 
 
 @media(min-width:768px) and (max-width:1024px){
   .head-links-mobile1En{
     display: none;
   }
   .app-menu .menu-sign-in {
     padding-bottom: 0;
     margin-bottom: 0px;
   }
 }
 