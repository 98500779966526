/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.signin-container {
  min-height: 76vh;
  display: flex;
  justify-content: center;
  font-family: "changa";
  background-color: #0F1822; }
  .signin-container .login {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center; }
    @media (max-width: 1024px) {
      .signin-container .login {
        width: 100%; } }
    .signin-container .login .overlay-title {
      color: #ebebeb;
      font-size: 20px;
      margin: 0 0 20px;
      text-transform: uppercase;
      text-align: center;
      display: block;
      width: 100%;
      margin-top: 100px; }
      @media (max-width: 1024px) {
        .signin-container .login .overlay-title {
          font-size: 18px;
          margin-top: 122px; } }
    .signin-container .login .login-container {
      display: flex; }
      @media (max-width: 1024px) {
        .signin-container .login .login-container {
          width: 80%;
          max-width: 400px;
          display: block; } }
      .signin-container .login .login-container .input-container .form {
        display: flex;
        flex-direction: column; }
        .signin-container .login .login-container .input-container .form .row {
          width: 100%;
          position: relative; }
          .signin-container .login .login-container .input-container .form .row input {
            margin: 0;
            min-width: 250px;
            width: 400px;
            height: 53px;
            border-radius: 5px;
            border: 2px solid #4a4a4a;
            padding: 6px 16px;
            font-size: 22px;
            position: relative; }
            @media (max-width: 1024px) {
              .signin-container .login .login-container .input-container .form .row input {
                width: 100%; } }
            @media (max-width: 745px) {
              .signin-container .login .login-container .input-container .form .row input {
                font-size: 16px;
                padding: 5px 5px; } }
          .signin-container .login .login-container .input-container .form .row input:-webkit-autofill,
          .signin-container .login .login-container .input-container .form .row input:-webkit-autofill:active,
          .signin-container .login .login-container .input-container .form .row input:-webkit-autofill:focus,
          .signin-container .login .login-container .input-container .form .row input:-webkit-autofill:hover {
            -webkit-text-fill-color: white !important; }
        .signin-container .login .login-container .input-container .form .error-message {
          color: #bd0000;
          font-style: normal;
          padding: 4px;
          margin-top: 0;
          margin-bottom: 6px;
          font-weight: 400;
          height: 17px;
          font-size: 12px;
          position: relative;
          align-items: baseline; }
      .signin-container .login .login-container .input-container .forgot-password-wrapper {
        width: 100%; }
        .signin-container .login .login-container .input-container .forgot-password-wrapper .forgot-password-text {
          margin: 0 10px;
          font-size: 18px;
          color: #9e9e9e;
          text-decoration: underline; }
          @media (max-width: 745px) {
            .signin-container .login .login-container .input-container .forgot-password-wrapper .forgot-password-text {
              font-size: 15px; } }
        @media (max-width: 1024px) {
          .signin-container .login .login-container .input-container .forgot-password-wrapper {
            width: 80%; } }
      .signin-container .login .login-container .input-container .btn-confirm {
        margin: 25px 0 0;
        border-radius: 5px;
        background-color: #ff740f;
        color: #fff;
        font-size: 18px;
        width: 400px;
        height: 55px; }
        @media (max-width: 1024px) {
          .signin-container .login .login-container .input-container .btn-confirm {
            width: 100%; } }
        @media (max-width: 745px) {
          .signin-container .login .login-container .input-container .btn-confirm {
            font-size: 18px; } }
        .signin-container .login .login-container .input-container .btn-confirm:not([disabled]):hover {
          background-color: #ff740f; }
        .signin-container .login .login-container .input-container .btn-confirm:not([disabled]).md-focused {
          background-color: #ff740f; }
      .signin-container .login .login-container .or-container {
        width: 60px;
        margin: 30px 30px 0;
        display: flex;
        flex-direction: row;
        justify-content: center; }
        @media (max-width: 1024px) {
          .signin-container .login .login-container .or-container {
            width: 80%;
            height: 1px;
            margin: 50px auto; } }
        .signin-container .login .login-container .or-container .or-text {
          width: 50px;
          line-height: 0;
          text-align: center;
          font-size: 16px;
          color: #656565;
          display: none; }
          @media (max-width: 1024px) {
            .signin-container .login .login-container .or-container .or-text {
              display: block; } }
        .signin-container .login .login-container .or-container .or-line {
          width: 0;
          height: 167px;
          border: 0.5px solid #414141; }
          @media (max-width: 1024px) {
            .signin-container .login .login-container .or-container .or-line {
              height: 1px;
              width: 80%; } }
      .signin-container .login .login-container .social-login-container {
        margin-top: 8px; }
        .signin-container .login .login-container .social-login-container .social-login-text {
          font-size: 18px; }
          @media (max-width: 745px) {
            .signin-container .login .login-container .social-login-container .social-login-text {
              font-size: 16px; } }
    .signin-container .login .redirection-container {
      height: 48px;
      font-size: 18px;
      color: #9e9e9e;
      display: flex;
      justify-content: center;
      margin: 40px auto;
      flex-direction: column;
      align-items: center; }
      @media (max-width: 745px) {
        .signin-container .login .redirection-container {
          font-size: 16px; } }
      .signin-container .login .redirection-container .redirection-name {
        font-size: 18px;
        color: #9e9e9e;
        text-decoration-line: inherit; }
        @media (max-width: 745px) {
          .signin-container .login .redirection-container .redirection-name {
            font-size: 16px; } }
  .signin-container .rodal-dialog {
    padding: 0; }
    .signin-container .rodal-dialog .rating-dialog-content .dialog-title {
      text-align: center;
      word-wrap: break-word;
      color: #606060;
      font-size: 22px;
      letter-spacing: 0.005em; }
    .signin-container .rodal-dialog .rating-dialog-content .dialog-ok-btn {
      border: 1px solid #606060;
      border-bottom: none;
      width: 100%;
      text-transform: none;
      color: #606060 !important;
      padding: 0;
      margin: 0 0 -17px !important;
      border-radius: 0; }
      .signin-container .rodal-dialog .rating-dialog-content .dialog-ok-btn:hover {
        background: #ff740f;
        color: white; }

.app-body[dir="rtl"] .signin-container .sign-up .login-facebook .social-login-text,
.app-body[dir="rtl"] .signin-container .sign-up .login-apple .social-login-text,
.app-body[dir="rtl"] .signin-container .sign-up .login-twitter .social-login-text {
  margin: 0 40px 0 0; }
  @media (max-width: 1024px) {
    .app-body[dir="rtl"] .signin-container .sign-up .login-facebook .social-login-text,
    .app-body[dir="rtl"] .signin-container .sign-up .login-apple .social-login-text,
    .app-body[dir="rtl"] .signin-container .sign-up .login-twitter .social-login-text {
      margin: 0 20px 0 0; } }
