/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.app-body[dir="rtl"] .terms-overlay .close-btn {
  right: auto;
  left: 20px; }

.terms-overlay {
  width: 100%;
  min-height: calc(100vh - 50px - 186px); }
  @media (max-width: 767px) {
    .terms-overlay {
      margin-top: -71px !important; } }
  .terms-overlay .close-btn {
    position: absolute;
    top: 27px;
    right: 28px;
    width: 30px;
    height: 30px; }
    @media (max-width: 745px) {
      .terms-overlay .close-btnhover {
        background-color: #ff740f; } }
    .terms-overlay .close-btn .icon {
      top: 0; }
  .terms-overlay .terms-overlay-container {
    width: 100%;
    padding: 0px 0;
    margin-bottom: -26px; }
    @media (max-width: 745px) {
      .terms-overlay .terms-overlay-container {
        padding: 93px 0; } }
    .terms-overlay .terms-overlay-container .static-page {
      padding: 0 0;
      background-color: #0F1822; }
      @media (max-width: 745px) {
        .terms-overlay .terms-overlay-container .static-page {
          padding: 0 10px; } }
      .terms-overlay .terms-overlay-container .static-page .overlay-title {
        color: white !important;
        max-width: 1300px;
        font-size: 55px !important;
        font-family: changa;
        width: 100%;
        margin: 0 auto !important;
        background-color: #0F1822 !important;
        padding: 85px 0 0 0 !important;
        font-weight: bold !important;
        text-align: center;
        margin-bottom: 26px; }
        @media (max-width: 767px) {
          .terms-overlay .terms-overlay-container .static-page .overlay-title {
            font: normal normal bold 31px/71px Changa !important; } }
      .terms-overlay .terms-overlay-container .static-page .static-content {
        max-width: 1300px;
        width: 100%;
        margin: 0 auto;
        background-color: #0F1822;
        padding: 0px 110px;
        font: normal normal normal 26px/25px Changa;
        letter-spacing: 0px;
        color: #707070;
        text-align: justify; }
        @media (max-width: 745px) {
          .terms-overlay .terms-overlay-container .static-page .static-content {
            padding: 15px;
            margin-top: -44px;
            text-align: justify;
            font: normal normal normal 16px/25px Changa;
            line-height: 18px; } }
        .terms-overlay .terms-overlay-container .static-page .static-content ol ul li {
          font: normal normal 200 21px/16px Changa !important; }
          @media (max-width: 767px) {
            .terms-overlay .terms-overlay-container .static-page .static-content ol ul li {
              font: normal normal 200 16px/16px Changa; } }
        .terms-overlay .terms-overlay-container .static-page .static-content h3 {
          margin: -40px 0 0 0;
          font-weight: lighter !important; }
          .terms-overlay .terms-overlay-container .static-page .static-content h3 strong {
            font-family: changa;
            font-size: 26px;
            color: #EE7623; }
            @media (max-width: 767px) {
              .terms-overlay .terms-overlay-container .static-page .static-content h3 strong {
                font-size: 21px !important; } }
        .terms-overlay .terms-overlay-container .static-page .static-content span {
          font-size: 26px;
          font-family: changa;
          color: #707070; }
          @media (max-width: 767px) {
            .terms-overlay .terms-overlay-container .static-page .static-content span {
              font-size: 21px !important; } }
        .terms-overlay .terms-overlay-container .static-page .static-content p {
          font: normal normal normal 26px/25px Changa;
          letter-spacing: 0px;
          color: #707070;
          letter-spacing: 0px;
          line-height: 30px; }
          @media (max-width: 767px) {
            .terms-overlay .terms-overlay-container .static-page .static-content p {
              text-align: justify;
              font: normal normal normal 16px/16px Changa;
              letter-spacing: 0px; } }
          .terms-overlay .terms-overlay-container .static-page .static-content p span {
            font-weight: bold; }
        .terms-overlay .terms-overlay-container .static-page .static-content strong {
          font-family: changa;
          font-weight: normal !important;
          font-size: 26px;
          color: #EE7623; }
          @media (max-width: 767px) {
            .terms-overlay .terms-overlay-container .static-page .static-content strong {
              color: #EE7623;
              font: normal normal 200 16px/16px Changa; } }
      .terms-overlay .terms-overlay-container .static-page.arabic {
        direction: rtl; }
  .terms-overlay .overlays-close {
    position: fixed;
    top: 65px;
    right: 15px;
    display: inline-block;
    width: 25px;
    height: 25px;
    overflow: hidden;
    cursor: pointer; }
    .terms-overlay .overlays-close::before, .terms-overlay .overlays-close::after {
      content: "";
      position: absolute;
      height: 1px;
      width: 100%;
      top: 50%;
      left: 0;
      margin-top: -1px;
      background: white; }
    .terms-overlay .overlays-close::before {
      transform: rotate(45deg); }
    .terms-overlay .overlays-close::after {
      transform: rotate(-45deg); }

.overlays.ng-enter {
  transition: 0.3s ease-in-out all;
  opacity: 0; }

.overlays.arabic .overlays-close {
  position: fixed;
  right: auto;
  left: 15px; }

.overlays.ng-enter.ng-enter-active {
  opacity: 1; }

@media (max-width: 745px) {
  .ltr .terms-overlay .terms-overlay-container .static-page .static-content p {
    text-align: left; }
  .terms-overlay .terms-overlay-container .static-page .static-content ol {
    text-align: left; } }

@media (max-width: 745px) {
  .rtl .terms-overlay .terms-overlay-container .static-page .static-content ol {
    text-align: justify; } }

@media (max-width: 745px) {
  .terms-overlay .terms-overlay-container .static-page .static-content ul {
    text-align: left; } }

@media (max-width: 745px) {
  .rtl .terms-overlay .terms-overlay-container .static-page .static-content ul {
    text-align: justify; } }
