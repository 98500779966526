/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
 @import "app/resources/styles/variables.scss";

 .app-body[dir="rtl"] button.subscribe-btn.btn {
   margin: 0px 0px 0px 21px;
   @media(max-width:1023) and (min-width:768px){
    margin: 0px 0px 0px 8px;

   }
 }
 
 .app-body[dir="rtl"] {
   .languagebtn-container {
     // margin-left: 0;
   }
   .header {
     .contentLeft {
       .menu-icon {
         padding: 0;
         // margin-right: 28px;
         display: flex;
         // margin-left: 56px;
          @media only screen and(max-width:1366px) and (min-width: 768px) {
           margin-left: 10px !important;
           margin-right: 1px !important;
         }
  @media(max-width:767px){
    display:none;
  }
         .icon {
           top: 0;
         }
       }
 
       .right-search-icon {
         display: grid;
 
         .icon {
           top: 0;
           //  transform: rotate(90deg);
           transform: translateY(-50%);
         }
         img {
           .icon {
            //  top: -6px;
           }
         }
       }
 
       .search-bar {
         margin: 0 0px 0 0;
        //  @media only screen and(max-width:1023px) and (min-width: 767px) {
          @media only screen and(max-width:1366px) and (min-width: 768px) {
           margin: 0 0px 0 0;
           display: block !important;
         }
         @media (max-width: 1440px) {
           margin: 0px;
           width: 180px;
         }
       }
     }
 
     .menu-icon {
       -webkit-transform: scaleX(-1);
       transform: scaleX(-1);
     }
 
     .langButton {
       margin: 0 8px 0 14px;
     }
 
     .search-bar {
       .left-search-icon {
         left: auto;
         right: 10px;
       }
 
       .search-input {
         margin-right: 41px;
         font-size: $header-search-input;
       }
     }
 
     .languagebtn-container {
       display: flex;
     }
 
     //   .lanchange-btn.btn {
     //   width: auto;
     //   height: 2.57em;
     //   margin: -10px -6px;
 
     //   @media only screen and (max-width: 745px) {
     //     display: block;
     //   }
     // }
 
     .contentRight {
       .sign-in-btn,
       .user-icon {
         //display: flex;
         display: none;
 
         .icon {
           top: 0;
         }
       }
 
       .right-search-icon {
         .icon {
           transform: rotate(90deg);
         }
       }
     }
   }
 
   @media only screen and(max-width:1023px) and (min-width: 767px) {
     .header {
       .contentRight {
         .sign-in-btn,
         .user-icon,
         .user-menu-container {
           display: none;
         }
       }
     }
   }
 
   button.subscribe-btn.btn {
     border-radius: 8px;
     background: transparent
       linear-gradient(180deg, var(--unnamed-color-ee7623) 0%, #ec1c24 100%) 0%
       0% no-repeat padding-box;
     background: transparent linear-gradient(180deg, #ee7623 0%, #ec1c24 100%) 0%
       0% no-repeat padding-box;
     border-radius: 13px;
     opacity: 1;
     border-radius: 13px;
     //  color: #ff6d02;
     font-weight: 300;
     font-size: 16px;
     font-family: "changa";
     padding: 0 12px;
     //  height: 40px;
     line-height: 40px;
     white-space: nowrap;
     font: normal normal bold 15px / 21px changa;
     letter-spacing: 0px;
     color: #ffffff;
     margin-right: 0px;
     width: 130px;
     transition: all 0.3s ease;
     font: normal normal bold 15px / 21px SF Pro Text;
     font-family: 'changa-Regular';
     @media (max-width: 1440px) {
       width: 100px;
     }
    //  @media(max-width:1023px) and (min-width:768px){
      @media (max-width:1366px) and (min-width: 768px) {
      margin: 0px 0px 0px 8px !important;
      font-size: 12px;
      width: 100px !important;
      height: 34px;
  
     }
     @media only screen and (max-width: 745px) {
       .right-subscribe-btn {
         display: block;
       }
     }
   }
   button.lanchange-btn.btn {
     background-color: transparent;
     border: 1px solid #ffffff;
     border-radius: 13px;
     font-weight: 300;
     font-size: 16px;
     font-family: "changa";
     padding: 0 17px !important;
     height: 34px;
     margin: 0px;
     // margin-left: 19px;
     // margin-right: 15px;
    //  width: 74px;
    width: auto;
     transition: all 0.3s ease;
     @media only screen and (max-width: 1023px) and (min-width: 768px){    
      font-size: 13px;
      margin: 0px;
      font-family: "changa";
      padding: 0 10px !important;
      height: 34px;
      width: auto;
     }
     &:hover {
       //  background-color:#707070;
       color: white;
     }
     @media only screen and (max-width: 767px) {
       height: 30px;
       font-size: 11px;
       width: 66px;
      //  display: flex;
      //  justify-content: center;
      //  align-items: center;
      //  padding: 0 !important;
     }
     //  @media only screen and (max-width: 745px) {
     //    display: block;
     //  }
     //  @media only screen and (max-width: 360px) {
     //   display: block;
     // }
 
     .lanchange-btn.btn {
       width: auto;
       height: 2.57em;
       margin: -10px -6px;
     }
   }
 }
 
 .header {
   // logo jumping fix
   // width: 100vw;
   // padding-right: 16px;
   // padding-left: 0;
   //  background-color: $header-background-color;
   // box-shadow: 1.5px 2.6px 6px 0 rgba(0, 0, 0, 0.22);
   //  z-index: 1112;
 
   .btn {
     &:hover {
       background-color: transparent;
     }
   }
 
   .contentLeft {
     .menu-icon-mobile {
       padding: 0;
       display: flex;
       height: 40px;
      //  position: absolute;
      // position: inherit;
      //  top:0 !important;
      //  right: 0!important;
       z-index: 1;
   
       .icon {
         width: 45px;
         position: relative;
       }
     }
     .menu-icon {
       padding: 0;
       // padding-left: 5px;
       z-index: 1;
       margin: 0px;
 
       .icon {
         width: 30px;
       }
 
       &:hover {
         background-color: transparent;
       }
     }
   }
 
 .contentLeft {
   .menu-icon-mobileAr {
    padding: 0;
    display: flex;
    height: 40px;
   //  position: absolute;
   // position: inherit;
   //  top:0 !important;
   //  right: 0!important;
    z-index: 1;
     .icon {
       width: 45px;
       position: relative;
     }
   }
   .menu-icon {
     padding: 0;
     z-index: 1;
     margin: 0px;
 
     .icon {
       width: 30px;
     }
 
     &:hover {
       background-color: transparent;
     }
   }
 }
 
 .contentMiddle {
  justify-content: center;

  align-items: center;
  width: 6%;
  @media (max-width: 1440px) {
    // width: 12%;
  }
  .logo {
    width: auto;
    height: 40px;
    cursor: pointer;
  }
  .logoMobile {
    // width: auto;
    // height: 52px;
    // cursor: pointer;
    // position: fixed;
    // left: 9rem;
    // top: 2em;
    // display: none;
    width: auto;
    height: 34px;
    cursor: pointer;
    position: relative;
    left: 6.5rem !important;
    right: 10em;
    top: -0.2em;
@media(min-width:768px) and (max-width:1023px){
  position: initial;
}
    @media(max-width:450px){
      // left:35%;
    }
  }
}
// @media(max-width:1023px){
//   display: none;
// }
@media screen and (max-width: 1440px) and (min-width: 746px) {
  .logoMobile {
    left: unset !important;
  }
}
 
   .contentRight {
     //flex-basis: 130px;
     -webkit-box-pack: end;
     -ms-flex-pack: end;
     justify-content: flex-start;
     -webkit-box-align: center;
     -ms-flex-align: center;
     align-items: center;
     @media (max-width: 765px) {
       justify-content: flex-end;
     }
   }
 
   .search-icon {
     margin: 0;
 
     .icon {
       width: 15px;
       height: 24px;
       @media (max-width: 765px) {
         width: 12px;
         height: 12px;
       }
     }
 
     &.right-search-icon {
       display: none;
     }
   }
 
   .sign-in-btn {
     margin: 0 0 3px;
     display: none;
 
     span {
       font-size: $header-sign-in-button;
     }
   }
 
   .user-icon {
     margin: 0;
     padding-right: 0;
     display: none;
 
     .icon {
       width: 38px;
       height: 38px;
     }
   }
 
   .search-bar {
     //  display: flex;
     //  height: 34px;
     //  width: 41px;
     // //  margin-left: 15px;
     //  box-sizing: border-box;
     //  outline: 0;
     //  font-weight: 400;
     //  position: relative;
     // //  transition: width 0.3s ease;
     //  border: 1px solid #FFFFFF;
     //  border-radius: 13px;
 
     &.hasResults {
       border-bottom-left-radius: 0px;
       border-bottom-right-radius: 0px;
       .search-form {
         border-bottom-left-radius: 0px;
         border-bottom-right-radius: 0px;
       }
     }
     &.expand {
       border: none;
       width: calc(95% - 100px);
       width: 182px;
       .left-search-icon {
         margin: inherit;
         border: none;
       }
       .rightAr-search-icon {
         border: none;
         right: 125px;
         margin: inherit;
          @media only screen and(max-width:1366px) and (min-width: 768px) {
          right: unset !important;
          // left: -7px;
          margin-top: 0px;
         }
         @media (max-width: 1440px) {
           left: -7px;
         }
         .icon {
           top: -4px;
           @media(max-width:1366px) and (min-width:768px) and (orientation: landscape){
            // top: -25px !important;
            // left: 1px !important;
           }
          //  display: none !important;
         }
       }
       .search-form {
         display: flex;
         align-items: center;
         width: 182px;
         height: 39px;
         border: 1px solid #ffffff;
         border-radius: 13px;
         opacity: 1;
         @media only screen and(min-width:1024px) and (max-width: 1050px){
          width: 120px !important;
         }
        //  @media(max-width:1023px)and (min-width:768px){
          // @media only screen and(max-width:1366px) and (min-width: 768px)and (orientation: portrait) {
          // width: 147px ;
          // left: 0px !important;
          // margin-left: -30px !important;
        //  }
         @media only screen and(max-width:1366px) and (min-width: 768px) and (orientation: landscape) {
          // width: 127px;         
          left: -10px !important;
          right: 0px !important;

         }
         .select-box-container {
           display: inline-flex;
         }
       }
     }
 
     .search-icon {
       padding: 0;
 
       .icon {
         width: 17px;
         height: 20px;
       }
     }
 
     .search-form {
       width: 80%;
       position: relative;
       border-radius: 8px;
       opacity: 0;
 
       .select-box-container {
         margin: 0;
         width: 100%;
         position: absolute;
         z-index: 20;
         left: 0px;
         top: 40px;
         color: #969696;
         background-color: #414141;
         display: -ms-inline-flexbox;
         display: none;
         border-bottom-left-radius: 8px;
         border-bottom-right-radius: 8px;   
         .select-box {
           width: 100%;
           height: auto;
           position: relative;
           display: -ms-flexbox;
           background-color: #414141;
           display: flex;
           -ms-flex-direction: column;
           flex-direction: column;
           cursor: pointer;
           color: $grid-dropdown-text-color;
           z-index: 4;
           border-bottom-left-radius: 8px;
           border-bottom-right-radius: 8px;
 
           .select-box-elements {
             .nodata,
             a {
               // margin:  -0.5% 0;
               text-decoration: none;
               width: 100%;
               height: 30px;
               display: block;
               z-index: 20;
               left: -1px;
               top: 40px;
               color: #fff;
               background-color: #414141;
               //  padding: 0 15px;
               padding: 0 13px;
               line-height: 30px;
               text-overflow: ellipsis;
               white-space: nowrap;
               overflow: hidden;
               cursor: pointer;
               border: none;
               font-size: 12px;
               border-bottom: solid 1px #505050;
               &:first-child {
                 border-top: solid 1px #505050;
               }
 
               &:last-child {
                 border-bottom: none;
                 border-bottom-left-radius: 8px;
                 border-bottom-right-radius: 8px;
               }
 
               .select-element {
                 background-color: transparent;
                 color: #ffffff;
                 line-height: 30px;
                 height: 30px;
               }
               &:hover {
                 .select-element {
                  //  color: #ee7623;
                 }
               }
 
               &.nodata {
                 cursor: default;
 
                 &:hover {
                   .select-element {
                     color: #ffffff;
                   }
                 }
               }
             }
           }
         }
       }
     }
 
     .search-input {
       /* transition: opacity 0.3s ease; */
       border: none;
       width: calc(100% - 41px);
       // margin-left: 41px;
       padding: 0px 0px 0px 10px;
       color: white;
       background-color: rgba(255, 255, 255, 0);
       @media(max-width:1023px) and (min-width:768px){
        // display: none;
       }
       @media only screen and (max-width: $mobile-view-threshold-px) {
         width: 50px;
       }
 
       &::placeholder {
         color: $app-header-searchinput-placeholder;
       }
     }
     .search-inputAR {
       border: none;
       width: calc(100% - 41px);
       // margin-left: 41px;
       padding: 0px 10px 0px 10px;
       color: white;
       background-color: rgba(255, 255, 255, 0);
       @media only screen and (max-width: $mobile-view-threshold-px) {
         width: 50px;
       }
 
       &::placeholder {
         color: $app-header-searchinput-placeholder;
       }
     }
     .arrow-icon {
       position: absolute;
       top: 0;
       right: 12px;
       bottom: 0;
       height: 18px;
       margin: auto;
     }
 
     .arrow-down {
       transform: rotate(90deg);
     }
 
     .left-search-icon {
       right: 1px;
       position: absolute;
       z-index: 1;
       -webkit-transform: rotate(90deg);
       transform: rotate(90deg);
       border-radius: 13px;
       width: 37px;
       height: 37px;
       border: 1px solid #ffffff;
       border-radius: 13px;
       display: flex;
       justify-content: center;
       align-items: center;
        @media only screen and(max-width:1366px) and (min-width: 768px) {
        margin-top: 2px;
        width: 32px;
        height: 30px;
       }
       @media (max-width: 745px) {
         border: none;
       }
     }
     .rightAr-search-icon {
      //  right: 137px;
      left:-9px;
       position: absolute;
       z-index: 1;
       -webkit-transform: rotate(90deg);
       transform: rotate(90deg);
       border-radius: 13px;
       width: 37px;
       height: 37px;
       border: 1px solid #ffffff;
       border-radius: 13px;
       display: flex;
        justify-content: center;
        align-items: center;
       @media (max-width: 1440px) {
         left: 0px;
         right: unset;
       }
        @media only screen and(max-width:1366px) and (min-width: 768px) {
        // margin-top: 0px;
        width: 34px;
        height: 33px;
        
       }
       @media screen and (width: 1024px) and (orientation:portrait){
        display: flex;
        justify-content: center;
        align-items: center;
       }
       .icon {
        //  top: -6px;
          @media only screen and(max-width:1366px) and (min-width: 768px) {
          top: 0px;
         }
         @media only screen and(max-width:1366px) and (min-width: 768px) and (orientation:landscape){
          // top: -6px;
         }
       }
     }
   }
 
   .mobile-search-form {
     display: none;
     position: relative;
     padding: 0;
     top: 60px;
     left: 0;
     transition: height 0.3s ease;
     height: 0;
     z-index: 100;
     overflow: hidden;
     border-bottom: none;
     border-radius: 0 0 5px 5px;
 
     &.expand {
       height: auto;
       border-bottom: none;
 
       .select-box-container {
         margin: 0;
         width: 100%;
         z-index: 20;
         left: -1px;
         top: 49px;
         color: #969696;
         background-color: #414141;
         display: -ms-inline-flexbox;
         display: inline-flex;
         text-align: center;
 
         .select-box {
           width: 100%;
           height: auto;
           position: relative;
           display: -ms-flexbox;
           display: flex;
           -ms-flex-direction: column;
           flex-direction: column;
           cursor: pointer;
           color: $grid-dropdown-text-color;
           z-index: 4;
 
           .select-box-elements {
             .nodata,
             a {
               text-decoration: none;
               width: 100%;
               height: 44px;
               display: block;
               z-index: 20;
               left: -1px;
               top: 49px;
               color: #969696;
               background-color: #fff;
               padding: 0 15px;
               line-height: 44px;
               text-overflow: ellipsis;
               white-space: nowrap;
               overflow: hidden;
               cursor: pointer;
               border-bottom: solid 1px rgba(0, 0, 0, 0.1);
 
               &:first-child {
                 border-top: solid 1px grey;
               }
 
               &:last-child {
                 border-bottom: none;
               }
 
               .select-element {
                 background: transparent;
               }
 
               &.nodata {
                 cursor: default;
 
                 &:hover {
                   .select-element {
                     color: #969696;
                   }
                 }
               }
             }
           }
         }
       }
     }
 
     .search-input .search-inputAR {
       box-sizing: border-box;
       width: 100vw;
       border: none;
       text-align: center;
       height: 38px;
       font-size: $header-search-input;
       margin-top: 24px;
       display: none;
     }
   }
 
  .mobile-searchAr{
    z-index: 1;
    // position: absolute;
    
  }
  .mobile-searchEn{
    z-index: 1;
    // position: absolute;
    
  }
   .logo-container {
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    //  left: 94px !important;
    //  top: 3em;
   
    @media(max-width:767px){
      width: 100%;
      top: 0.5rem;
      z-index: 1;
      // z-index: 999;
    }

//     @media only screen and (max-width: 1366px) and (min-width: 768px)and (orientation: portrait)   {
//       left: 50px !important;
//   }
//   @media only screen and (max-width: 1365px) and (min-width: 768px)and (orientation: landscape)   {
//     left: -40px !important;
// }
// @media only screen   and (min-device-width: 1112px)   and (max-device-width: 1112px)   and (orientation: landscape)   and (-webkit-min-device-pixel-ratio: 2) {
//   left: 0px !important;
// }
// @media only screen and (max-width: 1366px) and (min-width: 768px)and (orientation: portrait)  {
//   left: 50px !important;
// }

// @media only screen and (max-width: 1365px) and (min-width: 768px)and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2)   {
//   left: -40px !important;
// }
  }
  .logo-containerAr {
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    // right:0px !important;
    // z-index: 10; 
    @media(max-width:767px){
      right: 0px !important;
      justify-content: center;
      align-items: center;
      display: flex;
      position: fixed;
      width: 100%;
      top: 0.5rem; 
      z-index: 1;
    }
    @media(max-width:767px){
      width: 100%;
      top: 0.5rem;
      z-index: 1;
      // z-index: 999;
    }
//     @media only screen   and (min-device-width: 1112px)   and (max-device-width: 1112px)   and (orientation: landscape)   and (-webkit-min-device-pixel-ratio: 2) {
//       right: 0px !important;
//     }
//     @media only screen and (max-width: 1366px) and (min-width: 768px)and (orientation: portrait)  {
//       right: 40px !important
//   }
  
// @media only screen and (max-width: 1365px) and (min-width: 768px)and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2)   {
//   right: -80px !important;
// }
  
  }
  
  .logoMobile {
    width: auto;
    height: 31px;
    position: relative;
    top: -1em;
    right: 81px;
    z-index: 20; 
  
    @media screen and (max-width: 1440px) and (min-width: 746px)  {
      right: unset;
    }
    @media(max-width:767px){
      position: static !important;
    }
}

@media screen and (max-width: 1440px) and (min-width: 746px) {
  .logo-container {
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    // left: 68px !important;
  }
} 

 }
 
 .vertical-separator {
   display: inline-block;
   height: 44px;
   width: 0;
   margin: -3px 9px -3px 12px;
   background-color: $header-veritcal-separator-background-color;
   -webkit-box-shadow: 1.5px 2.6px 0 0 rgba(0, 0, 0, 0.14);
   box-shadow: 1.5px 2.6px 0 0 rgba(0, 0, 0, 0.14);
   border: 1px solid #fff;
 
   &.hidden {
     display: none;
 
     @media only screen and (max-width: 1024px) {
       display: inline-block;
     }
   }
 
   &.visible {
     display: inline-block;
 
     @media only screen and (max-width: 1024px) {
       display: inline-block;
     }
   }
 }
 
 .ltr {
   .vertical-separator {
     margin: -3px 0 -3px 12px;
   }
 }
 
 .rtl {
   .vertical-separator {
     margin: -3px 9px;
   }
 }
 
 /*Device width pixel lesser than 1024px */
//  @media (min-width:768px) and (max-width:1023px){
  @media only screen and(max-width:1366px) and (min-width: 768px) {
  .header {
    .contentLeft {
      .menu-icon {
        .icon {
          width: 25px !important;
          height: 25px !important;
         
        }
      }
      }
    }
 }
 @media only screen and (max-width: 1024px) {
   .header {
     .contentLeft {
       .menu-icon {
         .icon {
           width: 51px;
           height: 20px;
          
         }

       }
     }
 
     .contentMiddle {
       .logo {
         height: 60px;
        //  @media (min-width:768px) and (max-width:1023px){
          @media only screen and(max-width:1366px) and (min-width: 768px) {
          height: 27px;
         }
      
       }
     }
   }
 }
//  @media only screen and (max-width: 1023px) and (min-width: 768px) {
  @media only screen and(max-width:1366px) and (min-width: 768px) {
  .header .contentLeft .menu-icon {
      padding-left: 5px;
      // margin-right: 10px !important;
      margin-right: 8px !important;
  }
}
 /*Device width pixel lesser than 860px */
 @media only screen and (max-width: 1023px) and (min-width: 767px) {
   .contentRight {
     padding-left: 15px;
     padding-right: 15px;
   }
   .languagebtn-container {
     margin-left: 5px !important;
     margin-right: 5px !important;
   }
   .header .contentLeft .menu-icon {
     padding-left: 5px;
    //  margin-right: 0px !important;
   }
   .header {
     .right-languagebutton {
       margin-right: 0 !important;
     }
   }
   .header {
     .contentMiddle {
       flex-grow: 1;
     }
 
     .langButton {
       .icon {
         width: auto;
         height: 2.51em;
         margin: -10px -6px;
       }
     }
 
     .search-bar {
       margin-left: 0px;
       margin-right: 5px;
     }
 
     .right-languagebutton {
       display: none;
     }
 
     .btn {
       padding: 0;
     }
 
     .search-icon {
       &.right-search-icon {
         display: none;
         border: 1px solid #707070;
         border-radius: 6px;
         padding: 0 8px;
         margin-right: 17px;
         margin-left: 17px;
         height: 30px;
         line-height: 0px;
         img {
           top: 0;
         }
       }
     }
 
     .vertical-separator {
       height: 38px;
       margin: -3px -3px -3px 9px;
 
       &.left-separator {
         display: none;
       }
     }
 
     .sign-in-btn,
     .user-icon,
     .user-menu-container {
       display: none;
     }
 
     .search-bar {
      //  display: block;
      //  padding: 0 19px;
     }
 
     .mobile-search-form {
      //  display: block;
     }
   }
 }
//  @media(max-width:1023px) and (min-width:768px){
  @media only screen and(max-width:1366px) and (min-width: 768px) {
  .mobile-search-form {
    display: none;
  }
 }
 /*Device width pixel lesser than 745*/
 @media only screen and (max-width: 767px) {
   .header {
     height: $header-height-mobile;
 
     .mobile-search-form {
       top: $header-height-mobile;
       // top: 0px;
       display: none;
     }
 
     .lanchange-btn.btn {
       width: auto;
       height: 2.57em;
       margin: -10px -6px;
 
       @media only screen and (max-width: 767px) {
         display: none;
       }
     }
 
     .search-form {
       display: block;
       margin-left: 0px !important;
       padding: 5px;
    position: relative;
     }
 
     .right-languagebutton {
       border-radius: 8px;
       background-color: transparent;
       border: 1px solid #707070;
       border-radius: 6px;
       color: #707070;
       font-weight: 300;
       font-size: 16px;
       font-family: "changa";
       padding: 0 12px !important;
       height: 40px;
       line-height: 40px;
       margin-left: 15px;
       margin-right: 15px;
       transition: all 0.3s ease;
       display: none;
 
       &:hover {
         background-color: #707070;
         color: white;
       }
     }
 
     .vertical-separator {
       height: 38px;
       margin: -3px -3px -3px 14px;
     }
   }
 }
 
 /*Device width pixel in between 360-450*/
 @media only screen and (max-width: 450px) {
   .header {
     // .contentLeft {
     //   flex-basis: 120px;
     //   padding-left: 5px;
     // }
 
     .langButton {
       .icon {
         width: auto;
         height: 2.57em;
         margin: -10px -11px;
       }
     }
 
     // .lanchange-btn.btn {
     //   width: auto;
     //   height: 2.57em;
     //   margin: -10px -6px;
 
     //   @media only screen and (max-width: 745px) {
     //     display: block;
     //   }
 
     // }
 
     .vertical-separator {
       height: 38px;
       margin: -3px -3px -3px 14px;
     }
   }
 }
 
 /*Device width pixel less than 360 */
 @media only screen and (max-width: 360px) {
 }
 
 button.subscribe-btn.btn {
   border-radius: 8px;
   background: transparent
     linear-gradient(180deg, var(--unnamed-color-ee7623) 0%, #ec1c24 100%) 0% 0%
     no-repeat padding-box;
   background: transparent linear-gradient(180deg, #ee7623 0%, #ec1c24 100%) 0%
     0% no-repeat padding-box;
   border-radius: 13px;
   opacity: 1;
   border-radius: 13px;
   font-weight: 300;
   font-size: 16px;
   font-family: "changa";
   padding: 0 12px;
   line-height: 35px;
   height: 40px;
   transition: all 0.3s ease;
   margin-right: 21px;
   width: 130px;
   white-space: nowrap;
   font: normal normal bold 15px / 21px changa;
   letter-spacing: 0px;
   color: #ffffff;
   font: normal normal bold 15px / 21px SF Pro Text;
   font-family: 'changa-Regular';
   .right-subscribe-btn {
     display: none;
   }
   @media(max-width:1023px) and (min-width:768px){
    // width: 121px;
    margin-right: 0px;
    font-size: 12px;
    width: 100px !important;
    height: 34px;
   }
   @media (max-width: 1440px) {
     width: 123px !important;
   }
 
   @media only screen and (max-width: 767px) {
     display: none;
   }
 }
 @media only screen and (max-width: 767px) {
   .right-subscribe-btn {
     display: block !important;
     padding: 0 5px !important;
     margin: 0 10px 0 0 !important;
     font-size: 12px !important;
   }
 }
 button.lanchange-btn.btn {
   background-color: transparent;
   border: 1px solid #ffffff;
   border-radius: 13px;
   font-weight: 300;
   font-size: 16px;
   margin: 0px;
   font-family: "changa";
   padding: 0 15px !important;
   height: 34px;
   // margin-left: 19px;
   // margin-right: 15px;
  //  width: 66px;
  width: auto;;
   transition: all 0.3s ease;
  //  @media only screen and (max-width: 1023px) and (min-width: 768px){ 
    @media only screen and(max-width:1366px) and (min-width: 768px) {   
    font-size: 13px;
    margin: 0px;
    font-family: "changa";
    padding: 0 10px !important;
    height: 30px;
    width: auto;
   }
 
   //  &:hover {
   //    background-color:#707070;
   //    color:white;
   //  }
   @media only screen and (max-width: 768px) {
     margin-left: 0;
     font-size: 11px;
     padding: 0 8px;
     height: 30px;
     line-height: 0px;
     width:66px;
   }
 }
 .rtl .header .search-bar .arrow-icon {
   right: inherit;
   left: 12px;
   -webkit-transform: rotate(-180deg);
   transform: rotate(-180deg);
 }
 
 .header .search-bar.hasResults .arrow-icon {
   -webkit-transform: rotate(-275deg);
   transform: rotate(-275deg);
 }
 
 .right-languagebutton {
   border-radius: 8px;
   background-color: transparent;
   border: 1px solid #707070;
   border-radius: 6px;
   color: #707070;
   font-weight: 300;
   font-size: 16px;
   font-family: "changa";
   padding: 0 12px !important;
   height: 40px;
   line-height: 40px;
   margin-left: 15px;
   margin-right: 0;
   transition: all 0.3s ease;
   display: none;
 
   &:hover {
     background-color: #707070;
     color: white;
   }
 }
 
 .rtl .search-bar .search-icon .icon {
   /*width: 20px;
   height: 20px;*/
   position: relative;
   top: 2px;
   right: 5px;
 }
 
 @media only screen and(max-width:1023px) and (min-width: 767px) {
   .app-body[dir="ltr"] .right-languagebutton {
     margin-right: 15px !important;
   }
   .app-body[dir="ltr"] .menu-icon {
     padding-left: 10px;
     padding-right: 0px;
   }
   .app-body[dir="rtl"] .header .right-languagebutton {
     margin-left: 12px;
   }
 }
 
 .expanded_search {
   transition: width 2s, height 4s;
 
   .contentLeft {
     @media only screen and(max-width:1023px) and (min-width: 767px) {
       // background-color: purple;
       width: auto !important;
 
       .search-bar {
         padding: 0 0px 0 10px;
         background-color: #414141;
 
         .search-input {
           font-size: 12px;
           width: 155px;
           margin-left: 0;
           padding-left: 30px;
         }
         .arrow-icon {
           right: 6px;
           bottom: 0;
           height: 23px;
           margin: auto;
           background-color: #414141;
         }
         &.expand {
           width: calc(95% - 70px);
           width: 175px;
           // overflow: hidden;
         }
       }
 
       .select-box {
         left: -10px;
       }
     }
   }
   .contentMiddle {
     @media only screen and(max-width:1023px) and (min-width: 767px) {
       // background-color: green;
       width: 70px !important;
       display: flex;
       justify-content: flex-end;
     }
   }
   .contentRight {
     @media only screen and(max-width:1023px) and (min-width: 767px) {
       // background-color: gold;
       width: 100px !important;
     }
   }
 }
 
 .expanded_search {
   @media only screen and(max-width:376px) {
     // background-color: gold;
 
     transition: width 2s, height 4s;
 
     .contentLeft {
       width: 210px !important;
 
       .search-input {
         width: 115px !important;
       }
     }
   }
 }
 
 @media only screen and(max-width:1023px) and (min-width: 767px) {
   .rtl {
     .expanded_search .contentLeft .search-bar .search-input {
       width: 130px;
       padding-left: 20px;
       padding-right: 14px;
     }
     .expanded_search .contentLeft .search-bar .arrow-icon {
       right: inherit;
       left: -4px;
     }
     .search-input {
       margin-right: 20px !important;
       font-size: 16px !important;
     }
 
     .right-subscribe-btn {
       // display: block !important;
       padding: 0 14px !important;
       margin: 0 10px 0 0 !important;
       font-size: 12px !important;
     }
   }
 }
 
 select-box .select-box-elements {
   top: 39px;
 }
 .select-box-container .selected .btn .icon {
   width: 20px;
   top: -5px;
 }
 .select-box-container label {
   margin-left: 10px !important;
   height: 39px;
   line-height: 39px;
 }
 .rtl .search-bar.expand {
   width: 168px;
 }
 
 header {
   .select-box-container .select-box {
     background-color: grey;
     border-radius: 8px;
     height: 39px;
   }
   .select-box-container .select-box .selected,
   .select-box-container .select-box .select-element {
     line-height: 39px;
     height: 39px;
     color: #ffffff;
     font-family: "changa-regular";
     background-color: #414141;
     border-radius: 8px;
     font-size: 12px;
     @media(max-width:745px){
       background-color: #0f1822;
       text-decoration: none;
   
     a{
       text-decoration: none;
     }
   }
   }
   .select-box .select-box-elements {
     background-color: #414141;
     a{
       text-decoration: none;
     }
   }
   .select-box-container {
     .select-element {
       border-radius: 0 !important;
     }
   }
   .open .select-box-container .select-box .selected {
     border-bottom-right-radius: 0;
     border-bottom-left-radius: 0;
   }
   select-box.open .select-box-elements {
     border-bottom-right-radius: 8px;
     border-bottom-left-radius: 8px;
   }
   .select-box-container .select-box .selected {
     font-size: 12px;
   }
   @media (max-width: 737px) {
     .select-box-container label {
       height: 28px;
       line-height: 26px;
     }
   }
   @media only screen and (min-width: 768px) {
     .gridScreen .select-box-container {
       margin-top: 0 !important;
     }
   }
   @media only screen and (max-width: 745px) {
     .gridScreen .listing-filters .first-select-box {
       visibility: visible;
     }
     .gridScreen .listing-filters-mobile .container .filter-item {
       color: #818181;
       font-size: 16px;
       font-family: "changa-regular";
     }
   }
   @media (min-width: 320px) {
     .gridScreen .listing-filters .select-box-container {
       margin-left: 14px;
       margin-right: 14px;
     }
     .app-body[dir="rtl"] .listing-filters .select-box-container label {
       float: none;
     }
   }
 }
 @media (max-width: 768px) {
   .is-mobile {
     display: none;
   }
 }
 @media (max-width: 745px) {
   .contentLeft {
     height: inherit;
     box-sizing: border-box;
     align-items: center;
     justify-content: flex-start;
     position: relative;
   }
 }
 .contentLeft{
   .mobile-layout {
   // height: 100vh;
   height:192vh;
   background: #0f1822;
  // background: red;
   width: 100vw;
   margin: 0;
   padding: 0;
   position: relative;
  //  margin-left: -51px;
   @media(max-width:767px){
    position: absolute;
    /* top: 0; */
    left: 0 !important;
    height: 100vh;
    bottom: 0 !important;
    padding: 8rem 0 0 0;
   }
//  @media(max-width:360px) and (min-width:400px){
//   padding:10rem 0 0 0
//  }
 }
 
 }
 .h2form {
   color: red;
   padding: 0px;
   right: 12em;
   position: absolute;
 } 

 .search-input-mobile {
  background: black;
  position: relative;
  width:100%;
  font: normal normal normal 14px / 22px Changa;
  height: 35px;
  border: 5px;
  border-radius: 10px;
  color: #ebebf599;
  padding-left: 1.7rem; 
}


 .search-icon-Mobile{
   z-index: 1;
   position: absolute;
   top: 1rem !important;
   left: 12px !important;
   height: 16px;
   width: 16px; 
 }
 
 .search-input-mobileAr {
   background: black;
   position: relative;
   right: 0em;
  //  width: 25em;
  width: 100%;
   font: normal normal normal 14px / 22px Changa;
   height: 35px;
   border: 5px;
   border-radius: 10px;
   color: #ebebf599;
   z-index: 1;
   top: 40.5em;
   padding-right: 1.7 rem;
   @media (max-width: 767px) {
     top: 0em !important;
     padding-right: 33px;
 }

 
 }
 .search-icon-mobileAr{
   z-index: 1;
   position: absolute;
   top: 1rem;
   right: 12px !important;
   height: 16px;
   width: 16px;
   transform: rotate(90deg);
  
 
 }
 @media only screen and (max-width: 767px) {
   .header_bottom {
     display: flex; 
     height: 100%;
     width: 100%;
     padding: 0;
   }
 }
 .search-bar {
   display: flex;
   align-items: center;
   position: relative;
   @media (max-width: 1440px) {
     width: 100%;
   }
 }
 
 .search-input .search-inputAR {
   width: 0;
   padding: 5px;
   transition: width 0.5s ease;
   border: none;
   border-bottom: 2px solid #ccc;
   background-color: #f0f0f0;
   font-size: 16px;
   opacity: 0; /* Hide initially */
   visibility: hidden;
 }
 
 .search-bar .expand .search-input .search-inputAR {
   opacity: 1;
   visibility: visible;
 }
 .search-icon {
   width: 30px;
   height: 30px;
   cursor: pointer;
   margin-left: 10px;
   @media (max-width: 745px) {
     width: 20px;
     height: 30px;
     cursor: pointer;
     margin-left: -15px;
     top: -3px;
     position: relative;
     top: -27px;
   }
 }
 .search-iconAR {
   width: 30px;
   height: 30px;
   cursor: pointer;
   margin-left: 10px;
 }
 @media(max-width:767px){
 .bottom-sticky{
	background:#0f1822;
  // background: red;
  width: 100%;
  display: flex;
  top: auto;
  position: fixed;
  left: 0;
  bottom: 0;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  z-index: 2;
  // display: none;
	}
}
	@media (max-width: 745px) and (max-width: 400px) and (min-width: 340px) {
    .header .mobile-searchEn {
        /* top: 36em !important; */
        // position: relative;
        z-index: 1;
        // top: 0 !important;
        // right: 0 !important;
    }
	}
  @media (max-width: 745px) and (max-width: 400px) and (min-width: 340px) {
    .header .mobile-searchAr {
        /* top: 36em !important; */
        // position: relative;
        z-index: 1;
        // top: 0 !important;
        // right: 0 !important;
    }
	}
	
	@media (max-width: 400px) and (min-width: 340px) {
    .header .contentLeft .menu-icon-mobile {
        /* top: 29em !important; */
        // position: relative;
        // top: 0 !important;
        // right: 0 !important;
    }
}
@media (max-width: 400px) and (min-width: 340px) {
  .header .contentLeft .menu-icon-mobileAr {
      /* top: 29em !important; */
      // position: relative;
      //  top: 0 !important;
       right: 0 !important;
  }
}
@media (max-width: 745px) {
    .header_bottom .contentLeft {
        width: 100%;
        left: 0;
        /* right: 378px; */
    }
    .header .mobile-searchEn {
      /* z-index: 1; */
      //  position: inherit; 
      //  right: 0 !important;
  }
}

@media(max-width:767px){
  .menu-icon {
    // display: none
  } 
}
// @media(max-width:1023px) and (min-width:768px){
  @media only screen and(max-width:1366px) and (min-width: 768px) {
.mobile-searchEn ,.mobile-searchAr{
  display: none;

}

 }
 @media(max-width:1366px) and (min-width:768px) and (orientation: landscape){
.search-icon{
  margin: 0px !important;
  margin-right: 10px !important
}
.rtl .search-icon {
  margin-left: 10px !important
}
.languagebtn-container {
  margin: 0px !important;
  margin-right: 10px !important
}
.rtl .languagebtn-container{
  margin-left: 10px !important;
}
button.subscribe-btn.btn{
  margin: 0px !important;
  margin-right: 10px !important
}


}



