/*
 * Copyright (C) 2014-2018 L&T Technology Services, All Rights Reserved.
 *
 * This source code and any compilation or derivative thereof is the
 * proprietary information of L&T and is confidential in nature.
 * Under no circumstances is this software to be exposed to or placed under
 * an Open Source License of any type without the expressed written permission
 * of L&T.
 */
/* colors */
/*Menu */
/*Footer */
/* Header */
/* Home Bucket item */
/* Grid */
/* image thumbnail */
/* language button */
/* Menu */
/* Scroll */
/* Smart TV Layout */
/* Smart TV Banner */
/* Video player */
/* Video info */
/* Video overview */
/* Login */
/* Sign-up */
/* Forgot Password */
/* my-activity */
/* My Account */
/* About, Privacy and Terms */
/* My Subscription */
/* Contact US */
.device-container {
  background-color: #0F1822;
  color: #4a4a4a;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 185px - 50px);
  font-family: "changa"; }
  @media only screen and (max-width: 767px) {
    .device-container {
      position: relative;
      top: 3.5em; } }
  .device-container .device {
    width: calc( 100% - 25vw);
    margin-bottom: 40px; }
    .device-container .device .overlay-title {
      color: #ffffff;
      font-size: 24px;
      font-weight: 400;
      text-align: center;
      margin: 30px 0; }
    .device-container .device .no-devices {
      font-size: 20px;
      color: #fff; }
    .device-container .device .device-info .device-info-title {
      color: #fff;
      margin-bottom: 40px;
      padding-top: 115px;
      font-size: 18px; }
      @media screen and (max-width: 745px) {
        .device-container .device .device-info .device-info-title {
          padding-top: 0px;
          font-size: 16px; } }
    .device-container .device .device-info .device-info-input .row {
      display: flex; }
      .device-container .device .device-info .device-info-input .row .inner-column {
        flex-basis: 100%; }
        .device-container .device .device-info .device-info-input .row .inner-column input {
          width: 100%;
          height: 50px;
          padding: 0 10px;
          border-radius: 5px;
          background-color: #0F1822;
          color: #646464;
          font-size: 18px;
          margin: 0;
          color: #888;
          font-size: 20px;
          border: 2px solid #4a4a4a; }
          @media screen and (max-width: 745px) {
            .device-container .device .device-info .device-info-input .row .inner-column input {
              font-size: 18px; } }
    .device-container .device .device-info .devices-info-image {
      text-align: center; }
      @media screen and (max-width: 900px) {
        .device-container .device .device-info .devices-info-image img {
          width: 100%; } }
    .device-container .device .device-info .device-info-text {
      text-align: center; }
      .device-container .device .device-info .device-info-text p {
        margin: 0;
        text-align: center;
        color: #fff;
        line-height: 24px;
        font-size: 14px; }
      .device-container .device .device-info .device-info-text .btn-add {
        border-radius: 5px;
        background-color: #ff6d02;
        padding: 10px 80px;
        margin: 20px 0;
        font-size: 18px; }
        @media screen and (max-width: 745px) {
          .device-container .device .device-info .device-info-text .btn-add {
            font-size: 14px; } }
      .device-container .device .device-info .device-info-text .message {
        background-color: rgba(0, 255, 0, 0.5);
        padding: 10px 5px;
        border-radius: 10px;
        font-size: 20px;
        color: #fff;
        text-align: center; }
      .device-container .device .device-info .device-info-text .error {
        background-color: rgba(255, 0, 0, 0.5); }
    .device-container .device .device-info .device-info-instructions {
      color: #717171;
      font-size: 18px; }
      @media screen and (max-width: 745px) {
        .device-container .device .device-info .device-info-instructions {
          font-size: 14px; } }
    .device-container .device .device-connected-devices .devices-title {
      color: #fff;
      font-size: 18px;
      line-height: 24px; }
    .device-container .device .device-connected-devices .sign-out-all {
      border-radius: 5px;
      background-color: #ff6d02;
      padding: 10px 80px;
      margin: 20px 0;
      font-size: 18px; }
      .device-container .device .device-connected-devices .sign-out-all:hover {
        background-color: #ff6d02; }
      @media screen and (max-width: 745px) {
        .device-container .device .device-connected-devices .sign-out-all {
          padding: 10px 20px;
          white-space: nowrap; } }
    .device-container .device .device-connected-devices .devices-signout-title {
      text-align: center; }
    @media screen and (max-width: 900px) {
      .device-container .device .device-connected-devices .device-item-name, .device-container .device .device-connected-devices .sign-out-btn, .device-container .device .device-connected-devices .sign-out-all {
        font-size: 14px; } }
